import styled from 'styles/styled';

export const Wrapper = styled.div`
  & + & {
    margin-top: 30px;
  }
`;

export const SliderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const SliderTitle = styled.p`
  color: ${({ theme }) => theme.palette.blogAccent};
  flex-basis: 100%;
  flex-grow: 1;
  font-weight: 400;
  padding-right: 10px;
  text-transform: uppercase;
`;

export const PaginationWrapper = styled.div`
  align-items: center;
  flex-basis: 0;
  display: flex;
  justify-content: center;
  flex-grow: 0;

  .swiper-pagination-bullet {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.palette.blogAccent};
    opacity: 1;
    transition: ${({ theme }) => theme.ui.transition('background-color')};

    &.swiper-pagination-bullet-active {
      background-color: ${({ theme }) => theme.palette.blogAccent};
    }

    & + .swiper-pagination-bullet {
      margin-left: 10px;
    }
  }
`;
