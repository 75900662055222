import styled from 'styles/styled';

export const ShareButton = styled.button`
  background-color: #005aa0;
  border-radius: 10px;
  color: ${({ theme }) => theme.palette.light};
  display: flex;
  align-items: center;
  padding: 8px 10px;

  .gatsby-image-wrapper {
    display: block;
    margin-right: 5px;
  }
`;

export const ShareDropdownWrapper = styled.ul<{ isOpen: boolean }>`
  background-color: ${({ theme }) => theme.palette.blogAccent};
  border-radius: 10px;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  padding: 15px;
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  transition: ${({ theme }) => theme.ui.transition('opacity')};
  z-index: 1;

  &::before {
    bottom: 100%;
    border-bottom: 5px solid ${({ theme }) => theme.palette.blogAccent};
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    content: '';
    height: 20px;
    left: calc(50% - 10px);
    position: absolute;
    width: 20px;
  }

  li + li {
    margin-top: 15px;
  }

  button {
    align-items: center;
    display: flex;
  }

  span {
    display: block;
    margin-left: 5px;
  }
`;
