import styled from 'styles/styled';
import { Link } from 'gatsby';

export const PageWrapper = styled.div`
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthMedium}px;
  margin: 0 auto;
  padding: 0 20px;

  @media ${({ theme }) => theme.devices.large} {
    padding: 0;
  }
`;

export const Grid = styled.div`
  @media ${({ theme }) => theme.devices.large} {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 30px;
  }
`;

export const Wrapper = styled.article`
  margin: 0 auto 30px;
  min-width: 0;
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthMedium}px;
  width: 100%;

  p {
    font-weight: 400;
  }

  > button {
    align-self: flex-start;

    p {
      font-size: ${({ theme }) => theme.fontSize.titleMobile};
    }
  }

  @media ${({ theme }) => theme.devices.large} {
    > button p {
      font-size: ${({ theme }) => theme.fontSize.title};
      line-height: normal;
      letter-spacing: normal;
    }
  }
`;

export const ArticleContent = styled.div`
  h2,
  p,
  ul,
  em,
  table {
    margin: 0 auto;
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSize.smallBase};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    margin: 30px auto 15px;
  }

  h3,
  h4 {
    color: ${({ theme }) => theme.palette.light};
    font-size: ${({ theme }) => theme.fontSize.titleMobile};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    margin: 30px auto 15px;
  }

  p,
  em {
    font-weight: ${({ theme }) => theme.fontWeight.light};
    margin-bottom: 15px;
  }

  a {
    font-size: inherit;
    text-decoration: underline;
  }

  em {
    font-style: italic;
  }

  ul {
    padding-left: 2em;
    margin: 15px auto;
  }

  li {
    font-weight: ${({ theme }) => theme.fontWeight.light};
    list-style-type: initial;
  }

  blockquote {
    font-style: italic;
  }

  figure {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    max-width: 100%;

    > span {
      margin: 0 auto;
    }

    img {
      border-radius: 4px;
      height: auto;
    }

    figcaption {
      display: none;
    }
  }

  // Source:
  // https://avexdesigns.com/blog/responsive-youtube-embed
  .wp-block-embed__wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }

  .wp-block-embed__wrapper iframe,
  .wp-block-embed__wrapper object,
  .wp-block-embed__wrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .wp-block-embed {
    width: 100%;
  }

  @media ${({ theme }) => theme.devices.large} {
    h2 {
      font-size: ${({ theme }) => theme.fontSize.subtitleMobile};
    }

    p,
    em,
    li {
      font-size: ${({ theme }) => theme.fontSize.subtitleMobile};
    }

    figure {
      + figure {
        margin-top: 0;
      }

      figcaption {
        color: ${({ theme }) => theme.palette.light};
        display: initial;
        font-size: ${({ theme }) => theme.fontSize.base};
        margin-top: 10px;
        opacity: 0.6;
      }
    }
  }
`;

export const BackWrapper = styled(Link)`
  align-items: center;
  display: flex;
  line-height: 1;

  span {
    border: 1px solid ${({ theme }) => theme.palette.blogAccent};
    border-radius: 50%;
    display: block;
    height: 1.5em;
    position: relative;
    width: 1.5em;

    &::before {
      border-left: 1px solid ${({ theme }) => theme.palette.blogAccent};
      border-top: 1px solid ${({ theme }) => theme.palette.blogAccent};
      bottom: 0.45em;
      content: '';
      left: 0.45em;
      position: absolute;
      right: 0.45em;
      top: 0.45em;
      transform: rotateZ(-45deg) translate(1px, 1px);
    }
  }

  p {
    color: ${({ theme }) => theme.palette.blogAccent};
    margin-left: 10px;
    text-transform: capitalize;
  }
`;

export const Heading = styled.header`
  border-bottom: 1px solid ${({ theme }) => theme.palette.blogAccent};
  margin: 30px 0;
`;

export const HeadingBottom = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
`;

export const ShareWrapper = styled.div`
  position: relative;
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.titleDesktop};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  @media ${({ theme }) => theme.devices.large} {
    font-size: ${({ theme }) => theme.fontSize.titleDesktopBig};
  }
`;

export const TagsWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const MetaWrapper = styled.div`
  margin-bottom: 30px;

  @media ${({ theme }) => theme.devices.medium} {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`;

export const AuthorWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const AuthorAvatar = styled.div`
  border-radius: 50%;
  height: 70px;
  object-fit: contain;
  margin-right: 10px;
  width: 70px;
`;

export const AuthorMeta = styled.div``;

export const AuthorName = styled.div`
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;

  a {
    display: block;
    margin-left: 10px;
  }

  img {
    height: 1.5em;
    width: 1.5em;
  }
`;

export const ArticleMeta = styled.div`
  display: flex;
  margin-top: 20px;

  @media ${({ theme }) => theme.devices.medium} {
    margin-top: 0;
  }
`;

export const ArticleDate = styled.div`
  align-items: center;
  display: flex;

  .gatsby-image-wrapper {
    margin-right: 5px;
  }

  & + & {
    margin-left: 20px;
  }
`;

export const FeaturedImgWrapper = styled.div`
  margin-bottom: 20px;

  @media ${({ theme }) => theme.devices.medium} {
    margin-bottom: 30px;
  }
`;

export const ImgCaption = styled.div`
  font-style: italic;
  opacity: 0.5;
  margin-top: 10px;
`;

export const RateWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.blogAccent};
  margin: 50px 0;
`;

export const OtherWrapper = styled.div`
  margin-bottom: 100px;
  margin-top: 50px;

  @media ${({ theme }) => theme.devices.medium} {
    display: flex;
  }
`;

export const OtherLabel = styled.p`
  color: ${({ theme }) => theme.palette.blogAccent};
  font-size: 22px;
  margin-bottom: 10px;
  text-align: center;

  @media ${({ theme }) => theme.devices.medium} {
    text-align: left;
  }
`;

export const OtherArticle = styled.div`
  & + & {
    margin-top: 30px;
  }

  @media ${({ theme }) => theme.devices.medium} {
    flex-basis: 50%;

    &:last-child {
      ${OtherLabel} {
        text-align: right;
      }
    }

    & + & {
      margin-left: 20px;
      margin-top: 0;
    }
  }
`;

export const SideBar = styled.div`
  display: none;

  @media ${({ theme }) => theme.devices.large} {
    display: block;
  }
`;
