import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import useOnclickOutside from 'react-cool-onclickoutside';
import {
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from 'react-share';

import { ShareButton, ShareDropdownWrapper } from './styled';

interface Props {
  url: string;
}

const ShareDropdown = ({ url }: Props) => {
  const [open, setOpen] = useState(false);
  const ref = useOnclickOutside(() => {
    setOpen(false);
  });

  return (
    <>
      <ShareButton onClick={() => setOpen(!open)}>
        <StaticImage
          src="../../images/blog/share.png"
          alt="Share icon"
          quality={100}
          placeholder="tracedSVG"
        />
        Share&nbsp;article
      </ShareButton>
      <ShareDropdownWrapper
        isOpen={open}
        onClick={() => setOpen(false)}
        ref={ref}
      >
        <li>
          <FacebookShareButton url={url}>
            <FacebookIcon
              bgStyle={{ fill: '#fff' }}
              iconFillColor="#28b4e1"
              size="21"
              round
            />
            <span>Facebook</span>
          </FacebookShareButton>
        </li>
        <li>
          <LinkedinShareButton url={url}>
            <LinkedinIcon
              bgStyle={{ fill: '#fff' }}
              iconFillColor="#28b4e1"
              size="21"
              round
            />
            <span>LinkedIn</span>
          </LinkedinShareButton>
        </li>
        <li>
          <RedditShareButton url={url}>
            <RedditIcon
              bgStyle={{ fill: '#fff' }}
              iconFillColor="#28b4e1"
              size="21"
              round
            />
            <span>Reddit</span>
          </RedditShareButton>
        </li>
        <li>
          <TelegramShareButton url={url}>
            <TelegramIcon
              bgStyle={{ fill: '#fff' }}
              iconFillColor="#28b4e1"
              size="21"
              round
            />
            <span>Telegram</span>
          </TelegramShareButton>
        </li>
        <li>
          <TwitterShareButton url={url}>
            <TwitterIcon
              bgStyle={{ fill: '#fff' }}
              iconFillColor="#28b4e1"
              size="21"
              round
            />
            <span>Twitter</span>
          </TwitterShareButton>
        </li>
      </ShareDropdownWrapper>
    </>
  );
};

export default ShareDropdown;
