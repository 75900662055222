import React from 'react';
import SwiperCore, { Pagination, EffectFade, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';

import { BlogTypes } from 'types';

import BlogCard from 'components/BlogCard';

import {
  PaginationWrapper,
  SliderHeader,
  SliderTitle,
  Wrapper,
} from './styled';

interface Props {
  posts: BlogTypes.BlogPost[];
  title: string;
}

SwiperCore.use([Pagination, EffectFade, Autoplay]);

const BlogSidebarSlider = ({ posts, title }: Props) => {
  const id = title.toLowerCase().split(' ').join('_');

  return (
    <Wrapper>
      <SliderHeader>
        <SliderTitle>{title}</SliderTitle>
        <PaginationWrapper id={id} />
      </SliderHeader>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        updateOnWindowResize
        preventInteractionOnTransition
        fadeEffect={{ crossFade: true }}
        effect="fade"
        pagination={{
          clickable: true,
          el: `#${id}`,
          type: 'bullets',
        }}
        autoplay={{ delay: 5000 }}
        observer
      >
        {posts.map((post) => (
          <SwiperSlide key={post.id}>
            <BlogCard
              author={post.author.node.name}
              date={post.date}
              title={post.title}
              slug={post.slug}
              image={post.featuredImage?.node.localFile.childImageSharp}
              short
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
};

export default BlogSidebarSlider;
