import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';
import { useScrollPercentage } from 'react-scroll-percentage';

import { theme } from 'styles/styled';
import homeBg from 'images/background/home-bg.jpg';

import { BlogTypes } from 'types';
import { ROUTES } from 'constants/common';

import SEO from 'components/SEO';
import Layout from 'components/Layout';
import BlogCard from 'components/BlogCard';
import BlogSidebarSlider from 'components/BlogSidebarSlider';
import ShareDropdown from 'components/ShareDropdown';
import Tag from 'components/Tag';
import TagsHeader from 'components/TagsHeader';
import FacebookIcon from 'images/icons/facebook_blue.svg';
import LinkedInIcon from 'images/icons/linked-in_blue.svg';
import TwitterIcon from 'images/icons/twitter_blue.svg';

import {
  ArticleContent,
  ArticleDate,
  BackWrapper,
  Heading,
  Grid,
  PageWrapper,
  OtherArticle,
  Title,
  Wrapper,
  RateWrapper,
  OtherWrapper,
  OtherLabel,
  TagsWrapper,
  MetaWrapper,
  AuthorWrapper,
  AuthorAvatar,
  AuthorMeta,
  AuthorName,
  ArticleMeta,
  HeadingBottom,
  SideBar,
  ShareWrapper,
} from './styled';

const BlogSingle = ({ data, pageContext }: BlogTypes.BlogPosts) => {
  const { href } = useLocation();
  const post = data.mainPost.nodes[0];
  const prevPost = pageContext?.prev;
  const nextPost = pageContext?.next;
  const [ref, percentage] = useScrollPercentage();

  if (post.seo) {
    post.seo.opengraphUrl = `${ROUTES.blog}/${post.slug}`;
  }

  return (
    <Layout
      backgroundColor={theme.palette.dark}
      backgroundImage={homeBg}
      percentage={percentage}
      sticky
    >
      <SEO metadata={post} />
      <PageWrapper>
        <TagsHeader />

        <Grid>
          <Wrapper>
            <BackWrapper to={ROUTES.blog}>
              <span>&nbsp;</span>
              <p>Back</p>
            </BackWrapper>
            <Heading>
              <Title>{post.title}</Title>

              <HeadingBottom>
                <TagsWrapper>
                  {post.tags.nodes.map((t) => (
                    <Tag key={t.id} name={t.name} slug={t.slug} />
                  ))}
                </TagsWrapper>

                <ShareWrapper>
                  <ShareDropdown url={href} />
                </ShareWrapper>
              </HeadingBottom>
            </Heading>

            <MetaWrapper>
              <AuthorWrapper>
                <AuthorAvatar>
                  <GatsbyImage
                    image={
                      post.author.node.avatarImg.childImageSharp.gatsbyImageData
                    }
                    alt={post.author.node.name}
                  />
                </AuthorAvatar>
                <AuthorMeta>
                  <AuthorName>
                    <p>{post.author.node.name}</p>
                    {post.author.node.seo.social.facebook && (
                      <a href={post.author.node.seo.social.facebook}>
                        <img src={FacebookIcon} alt="Facebook icon" />
                      </a>
                    )}
                    {post.author.node.seo.social.linkedIn && (
                      <a href={post.author.node.seo.social.linkedIn}>
                        <img src={LinkedInIcon} alt="LinkedIn icon" />
                      </a>
                    )}
                    {post.author.node.seo.social.twitter && (
                      <a href={post.author.node.seo.social.twitter}>
                        <img src={TwitterIcon} alt="Twitter icon" />
                      </a>
                    )}
                  </AuthorName>
                  <p>{post.author.node.description}</p>
                </AuthorMeta>
              </AuthorWrapper>

              <ArticleMeta>
                <ArticleDate>
                  <StaticImage
                    src="../../images/blog/time-icon.png"
                    alt="Date icon"
                    quality={100}
                    placeholder="tracedSVG"
                  />
                  <span>{pageContext?.readingTime.text}</span>
                </ArticleDate>
                <ArticleDate>
                  <StaticImage
                    src="../../images/blog/date-icon.png"
                    alt="Date icon"
                    quality={100}
                    placeholder="tracedSVG"
                  />
                  <span>{post.date}</span>
                </ArticleDate>
              </ArticleMeta>
            </MetaWrapper>

            {/* {post.featuredImage && (
            <FeaturedImgWrapper>
              <Img
                fluid={{
                  ...post.featuredImage.node.localFile.childImageSharp.fluid,
                }}
                alt={post.title}
              />
              {post.featuredImage.node.caption && (
                <ImgCaption
                  dangerouslySetInnerHTML={{
                    __html: post.featuredImage.node.caption,
                  }}
                />
              )}
            </FeaturedImgWrapper>
          )} */}
            <ArticleContent
              ref={ref}
              dangerouslySetInnerHTML={{
                __html: post.content.replace(/&nbsp;/g, ' '),
              }}
            />

            <RateWrapper />

            <OtherWrapper>
              {prevPost && (
                <OtherArticle>
                  <OtherLabel>Previous</OtherLabel>
                  <BlogCard
                    author={prevPost.author.node.name}
                    date={prevPost.date}
                    image={
                      prevPost.featuredImage?.node.localFile.childImageSharp
                    }
                    title={prevPost.title}
                    slug={prevPost.slug}
                  />
                </OtherArticle>
              )}
              {nextPost && (
                <OtherArticle>
                  <OtherLabel>Next</OtherLabel>
                  <BlogCard
                    author={nextPost.author.node.name}
                    date={nextPost.date}
                    image={
                      nextPost.featuredImage?.node.localFile.childImageSharp
                    }
                    title={nextPost.title}
                    slug={nextPost.slug}
                  />
                </OtherArticle>
              )}
            </OtherWrapper>
          </Wrapper>

          <SideBar>
            {pageContext && (
              <BlogSidebarSlider
                posts={pageContext.recentPosts}
                title="Most recent"
              />
            )}
            {pageContext && (
              <BlogSidebarSlider
                posts={pageContext.similarPosts}
                title="Most similar"
              />
            )}
          </SideBar>
        </Grid>
      </PageWrapper>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    mainPost: allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        ...PostMetaData
        title
        date(formatString: "DD.MM.YYYY")
        content
        categories {
          nodes {
            name
          }
        }
        slug
        featuredImage {
          node {
            id
            caption
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        author {
          node {
            avatarImg {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 70)
              }
            }
            name
            description
            seo {
              social {
                facebook
                linkedIn
                twitter
              }
            }
          }
        }
        tags {
          nodes {
            id
            name
            slug
          }
        }
      }
    }
  }
`;

export default BlogSingle;
